<template>
  <div class="auth-form mx-auto width-narrow mb-5">
    <div class="card mx-auto mb-5">
      <div class="card-body">
        <h1 class="heading-bigger text-center mb-4">
          Log in
        </h1>
        <f-alert
          v-if="authError"
          theme="danger"
          visible
          class="my-4">
          Authorization error. Please try again.
        </f-alert>

        <!--<f-alert
          v-if="additionalMessage.length"
          theme="success"
          visible
          centered
          class="my-4">
          {{ additionalMessage }}
        </f-alert>-->

        <validation-observer
          ref="login-form"
          tag="form">
          <validation-provider
            rules="required|email"
            name="email"
            v-slot="{ errors }">
            <div class="form-group">
              <!-- <label>E-mail</label> -->
              <input
                v-model="email"
                name="email"
                type="email"
                class="full-width"
                :state="errors.length ? 'invalid' : null"
                placeholder="E-mail Address" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            rules="required"
            name="password"
            v-slot="{ errors }">
            <div class="form-group">
              <!-- <label class="flex">
                Password
              </label> -->
              <input
                v-model="password"
                name="password"
                type="password"
                autocomplete="off"
                class="full-width"
                :state="errors.length ? 'invalid' : null"
                placeholder="Your Password" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              <div class="text-right">
                <router-link
                  tabindex="5"
                  to="/password-reset"
                  class="lnk"
                  :disabled="submitting">
                  Forgot your password?
                </router-link>
              </div>
            </div>
          </validation-provider>

          <f-button
            type="submit"
            theme="secondary"
            class="block mx-auto mt-3 text-uppercase"
            @click.prevent="validateForm"
            :disabled="submitting">
            <template v-if="!submitting">Log in</template>
            <template v-else>Logging in&hellip;</template>
          </f-button>
        </validation-observer>

        <div class="text-center">
          <f-button
            @click.native="$router.push('/register')"
            class="f-btn f-btn-primary mt-2 text-uppercase "
            :disabled="submitting">
            Register
          </f-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'Login',
  data () {
    return {
      authError: false,
      submitting: false,
      email: '',
      password: '',
    }
  },
  methods: {
    validateForm () {
      FormUtils.validate(this.$refs['login-form'], this.login);
    },
    login () {
      this.authError = false;
      this.submitting = true;
      delete this.$http.defaults.headers['X-Csrf-Token'];

      this.$http.post('/api/auth/login', {
        email: this.email,
        password: this.password
      }).then((response) => {
        this.submitting = false;
        this.$http.defaults.headers['X-Csrf-Token'] = `${response.data.csrf_token}`;
        this.$store.commit('setUserID', response.data.id);
        this.$store.commit('setUserEmail', response.data.email);
        this.$store.commit('setUserFirstName', response.data.first_name);
        this.$store.commit('setUserType', response.data.is_admin ? 'admin' : 'member');
        this.$store.commit('setUserAvatar', response.data.avatar);
        this.$store.commit('setUserPermissions', response.data.permissions);
        window.localStorage.setItem('last-token-refresh', +new Date());

        Vue.nextTick(() => {
          this.$router.push(response.data.is_admin ? '/users' : '/videos');
        });
      }).catch((error) => {
        console.log(error);
        this.submitting = false;
        this.authError = true;
      });
    }
  }
}
</script>
